.App {
  text-align: center;

  /*padding: 30px;*/
  /*  background: #000;*/
  /*  color: #fff;*/
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.6;
}

.main-grid {
    position: relative;
    z-index: 1;
    overflow: visible;
}

.App .background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(./assets/images/tennis-paddles-balls-arrangement_400_327_flipped.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #80ced6;
    opacity: 0.45;
    width: 100%;
    height: 250px;
}

@media only screen and (max-width: 422px) {
    .wrapper .background-image-bottom {
        position: absolute;
        z-index: -2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /*background: url(./assets/images/tennis-paddles-balls-arrangement_40_27.jpg) no-repeat center center fixed;*/
        background-image: url(./assets/images/tennis-paddles-balls-arrangement_40_27.jpg);
        background-repeat: no-repeat;
        background-position: center;
        /*background-attachment: scroll;*/
        /*background-size: 100vw 100vh;*/
        background-size: cover;
        /*background-color: #80ced6;*/
        opacity: 0.90;
        width: 100%;
        height: 1280px;
        /*overflow-x: scroll;*/
    }

    .wrapper {
        display: flex;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;

    }
}

    /*filter: blur(10px);*/

/* Mobile landscape breakpoint. */
@media only screen and (min-width: 422px) {
    .App .background-image {
        background-image: url(./assets/images/tennis-paddles-balls-arrangement_1000_667_flipped.jpg);
        width: 100%;
        height: 1280px;
        opacity: 1.0;
    }
}

/* Tablet breakpoint. */
@media only screen and (min-width: 768px) {
    .App .background-image {
        background-image: url(./assets/images/tennis-paddles-balls-arrangement_1000_667_flipped.jpg);
        width: 100%;
        height: 1280px;
        opacity: 1.0;
    }
}

/* Laptop breakpoint. */
@media only screen and (min-width: 1024px) {
    .App .background-image {
        background-image: url(./assets/images/tennis-paddles-balls-arrangement_1500_1000_flipped.jpg);
        /*background-size: 100vw 100vh;*/
        width: 100%;
        height: 1280px;
        opacity: 1.0;
    }
}

/* Desktop breakpoint. */
@media only screen and (min-width: 1600px) {
    .App .background-image {
        background-image: url(./assets/images/tennis-paddles-balls-arrangement_2000_1333_flipped.jpg);
        width: 100%;
        height: 1280px;
        opacity: 1.0;
    }
}
